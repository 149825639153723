import { Helmet } from "react-helmet-async";

import classes from "./Terms.module.css";
import { companyName } from "../../util/config";

const Terms = () => {
  return (
    <section className={classes.container}>
      <Helmet>
        <title>TERMS OF SERVICE</title>
        <meta name="description" content={`${companyName} terms of service`} />
        <link rel="canonical" href="/terms" />
      </Helmet>
      <h2 className={classes.heading}>Terms of Service</h2>
      <div className={classes.terms}>
        <h3 className={classes.headingSecondry}>Deposit/Withdrawals</h3>
        <div className={classes.content}>
          <h3>Cryptocurrency withdrawal</h3>
          <p>
            All Withdrawal requests are processed within 2-48 hours. Minimum
            withdrawal to be processed for Premium Account holders is $100,000.
            Capital Gains Taxes may apply on certain withdrawals. Withdrawal
            fees are adjusted according to blockchain conditions
          </p>
          <p>
            Please note that for security reasons, {companyName} maintains
            limited crypto-currency balances in its on-line hot wallet. In some
            cases, we will need to manually refund the hot wallet, and/or
            manually review and approve large transactions, which may take up to
            12 hours.
          </p>
        </div>
        <div className={classes.content}>
          <h3>Changes in Fees</h3>
          <p>
            {companyName} reserves the right to review and change any fees. Any
            change to our fee schedule, will be emailed and/or posted in
            Announcements section no fewer than three (3) calendar days before
            changes are to take effect.
          </p>
        </div>
        <div className={classes.content}>
          <h3>Termination Clause</h3>
          <p>
            This clause outlines the termination rights of both parties involved
            in the investment agreement. Either party has the option to
            terminate the agreement by providing a one-month notice period.
          </p>
          <p>
            In the event that the client decides to terminate the investment
            agreement prior to the completion of the contract term, a redemption
            fee of thirty percent (30%) will be incurred. Conversely, if the
            agreement is terminated by {companyName}, the company, no redemption
            fee will be imposed.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Terms;
